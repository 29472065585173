* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    color: #737380;
    background: #F5F5F5;
}

body, input, button, textarea {
    font: 600 18px 
}

body {
    -webkit-font-smoothing: antialiased;
}

body, input, button, textarea, select {
    font: 600 12px Montserrat, sans-serif;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

button {
    cursor: pointer;
}

input {
    border: 0;
}

textarea:focus, input:focus{
    outline: none;
}